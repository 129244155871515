import { ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { GameState } from '@/shared/enums/GameState';
import { LSKeys, LocalStorageService } from '@/shared/utils/local-storage';
import { TScreen } from '@/shared/utils/screensize';
import { createEvent, createStore, sample } from 'effector';

export enum ARK_MESSAGES {
    END_PREROLL = 'arkadium:endPreroll',
    START_PREROLL = 'arkadium:startPreroll',
    START_INTERSTITIAL = 'arkadium:startInterstitial',
    START_REWARD = 'arkadium:startReward',
}
export const gameStateUpdated = createEvent<GameState>();
export const $gameStateStore = createStore<GameState>(GameState.PENDING, { name: 'gameState' });
export const $shouldShowPreroll = createStore<boolean>(true);
export const checkPrerollShowRate = createEvent<{
    isPrerollEnabled: boolean;
    prerollShowRate: ConfigGamePage['prerollShowRate'];
}>();
const GAME_PLAY_CLEAR_TIME = 12; // hours
sample({
    clock: gameStateUpdated,
    source: $gameStateStore,
    fn: (currentGameState, passedGameState) => {
        if (currentGameState === GameState.ADBLOCK) {
            return currentGameState;
        }
        if (
            (currentGameState === GameState.PREROLL || currentGameState === GameState.PREROLL_PLAYING) &&
            passedGameState === GameState.GAME
        ) {
            const gamePlayCount = Number(LocalStorageService.getItem(LSKeys.gamePlayCount));
            LocalStorageService.setItem(LSKeys.gamePlayCount, String(gamePlayCount + 1));
        }
        return passedGameState;
    },
    target: $gameStateStore,
});

sample({
    clock: checkPrerollShowRate,
    fn: ({ isPrerollEnabled, prerollShowRate }) => {
        if (!isPrerollEnabled) {
            return false;
        }
        if (prerollShowRate === 1 || prerollShowRate === null) {
            return true;
        }

        const lastGamePlay = LocalStorageService.getItem(LSKeys.lastGamePlay);
        const needClearGamePlays =
            lastGamePlay && (Date.now() - Date.parse(lastGamePlay)) / 1000 / 60 / 60 >= GAME_PLAY_CLEAR_TIME;
            
        if (needClearGamePlays) {
            LocalStorageService.removeItem(LSKeys.gamePlayCount);
        }

        LocalStorageService.setItem(LSKeys.lastGamePlay, new Date().toString());

        const gamePlayCount = Number(LocalStorageService.getItem(LSKeys.gamePlayCount));
        return gamePlayCount % prerollShowRate === 0;
    },
    target: $shouldShowPreroll,
});
