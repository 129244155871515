'use client';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import {
    $gdprConsentStore,
    $isGDPRApplies,
    $targetingConsentStore,
    consentStoresInitFx,
    globalPrivacyControlFlagUpdated,
    msInitialConsentUpdated,
} from '@/features/analytics/model';
import { $isMicrosoftDomain, THIRD_PARTY_ADS_OPT_OUT_COOKIE_NAME } from '@/app.model';

import { aiAnalyticsStartedFx } from '@/features/analytics/AI/model';
import { gaAnalyticsStartedFx } from '@/features/analytics/GA/model';
import { getBrowserCookie } from '@/shared/utils/utils';

export const useCMPInit = () => {
    const initConsentStores = useUnit(consentStoresInitFx);
    const gdprConsent = useUnit($gdprConsentStore);
    const targetingConsent = useUnit($targetingConsentStore);
    const isGDPRApplies = useUnit($isGDPRApplies);
    const isMicrosoft = useUnit($isMicrosoftDomain);
    const initAI = useUnit(aiAnalyticsStartedFx);
    const initGA = useUnit(gaAnalyticsStartedFx);
    const updateMsInitialConsent = useUnit(msInitialConsentUpdated);
    const updateGPC = useUnit(globalPrivacyControlFlagUpdated);

    useEffect(() => {
        const thirdPartyAdsOptOutCookie = getBrowserCookie(THIRD_PARTY_ADS_OPT_OUT_COOKIE_NAME) ?? null;
        updateMsInitialConsent({ isMicrosoft, thirdPartyAdsOptOutCookie });
    }, [isMicrosoft, updateMsInitialConsent]);

    useEffect(() => {
        // @ts-expect-error globalPrivacyControl is not introduced as a default feature for all browsers, curently supported only in Firefox 120+
        const globalPrivacyControlFlag = navigator.globalPrivacyControl;
        if (globalPrivacyControlFlag) {
            updateGPC(globalPrivacyControlFlag);
        }
    }, [updateGPC]);

    useEffect(() => {
        initConsentStores();
    }, [initConsentStores]);

    useEffect(() => {
        if (gdprConsent) {
            initAI();
        }
    }, [gdprConsent, initAI]);

    useEffect(() => {
        //init GA for non-gdpr users
        if (!isGDPRApplies && targetingConsent) {
            initGA();
        }
    }, [initGA, isGDPRApplies, targetingConsent]);
};
