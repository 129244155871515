import { createEffect, createEvent, createStore, is, sample, scopeBind } from 'effector';
import { fetchData, geoServiceOptions } from '@/shared/api/data-fetcher';
import { LocalStorageService, LSKeys } from '@/shared/utils/local-storage';
import { $arenaDataStore, $isMicrosoftDomain } from '@/app.model';
import { externalScriptLoaded, externalScriptsUpdated } from '@/features/external-scripts/model';
import { TExternalScriptRaw } from '@/shared/api/arena-data';
import { $isCmpAndAdsDisabledByRegion } from '../ad/model';
import { logger } from '@/shared/utils/logger';

interface GeoIpResponse {
    ip: string;
    country_code: string;
    country_name: string;
    region_code: string;
    region_name: string;
    city: string;
    zip_code: string;
    time_zone: string;
    latitude: number;
    longitude: number;
    metro_code: number;
}
export const fetchUserGeoData = async (): Promise<GeoIpResponse> =>
    await fetchData(process.env.GEO_SERVICE_URL, geoServiceOptions);

export const $userCountry = createStore<string | null>(null);
export const fetchUserCountryFx = createEffect<void, GeoIpResponse>(fetchUserGeoData);
sample({
    clock: fetchUserCountryFx.doneData,
    filter: (data) => !!data,
    fn: (data: GeoIpResponse) => {
        return data.country_code;
    },
    target: $userCountry,
});

export enum CUSTOM_BEHAVIOUR_REGIONS {
    //USA, TODO: what about California with their CCPA?
    US = 'US',
    AS = 'AS', //American Samoa
    GU = 'GU', //Guam
    MP = 'MP', //Northern Mariana Islands
    PR = 'PR', //Puerto Rico
    UM = 'UM', //U.S. Minor Outlying Islands
    VI = 'VI', //U.S. Virgin Islands

    //EEA included outermost regions / GDPR
    AT = 'AT', //Austria
    AX = 'AX', //Åland Islands
    BE = 'BE', //Belgiuutilsm
    BG = 'BG', //Bulgaria
    CY = 'CY', //Cyprus
    CZ = 'CZ', //Czech Republic
    DE = 'DE', //Germany
    DK = 'DK', //Denmark
    EE = 'EE', //Estonia
    EL = 'EL', //Greece
    ES = 'ES', //Spain
    EU = 'EU', //European Union
    FI = 'FI', //Finland
    FR = 'FR', //France
    GB = 'GB', //United Kingdom (Great Britain)
    GF = 'GF', //French Guiana
    GI = 'GI', //Gibraltar
    GP = 'GP', //Guadeloupe
    GR = 'GR', //Greece
    HR = 'HR', //Croatia
    HU = 'HU', //Hungary
    IE = 'IE', //Ireland, Republic of (EIRE)
    IS = 'IS', //Iceland
    IT = 'IT', //Italy
    LI = 'LI', //Liechtenstein
    LT = 'LT', //Lithuania
    LU = 'LU', //Luxembourg
    LV = 'LV', //Latvia
    ME = 'ME', //Montenegro
    MF = 'MF', //Saint Martin
    MQ = 'MQ', //Martinique
    MT = 'MT', //Malta
    NL = 'NL', //Netherlands
    NO = 'NO', //Norway
    PL = 'PL', //Poland
    PT = 'PT', //Portugal
    RE = 'RE', //Réunion
    RO = 'RO', //Romania
    SE = 'SE', //Sweden
    SI = 'SI', //Slovenia
    SK = 'SK', //Slovakia
    YT = 'YT', //Mayotte
    UK = 'UK', //United Kingdom (Great Britain)

    CA = 'CA', //Canada
    BR = 'BR', //Brazil

    //No consent regions, disabling ads and analytics
    //China / PIPL
    CN = 'CN', //China
    HK = 'HK', //Hong Kong
    MO = 'MO', //Macao
}

type OutOfBand = {
    allowedVendors: Record<string, unknown>;
    disclosedVendors: Record<string, unknown>;
};

type Purpose = {
    consents: Record<string, unknown>;
    legitimateInterests: Record<string, unknown>;
};

type Vendor = {
    consents: Record<string, unknown>;
    legitimateInterests: Record<string, unknown>;
};

type SpecialFeatureOptins = {
    [key: string]: unknown;
};

type Publisher = {
    consents: Record<string, unknown>;
    legitimateInterests: Record<string, unknown>;
    customPurpose: Purpose;
    restrictions: Record<string, unknown>;
};

type CMPData = {
    cmpId: number;
    cmpVersion: number;
    gdprApplies: boolean;
    tcfPolicyVersion: number;
    tcString: string;
    listenerId: number;
    eventStatus: string;
    cmpStatus: string;
    isServiceSpecific: boolean;
    useNonStandardTexts: boolean;
    publisherCC: string;
    purposeOneTreatment: boolean;
    outOfBand: OutOfBand;
    purpose: Purpose;
    vendor: Vendor;
    specialFeatureOptins: SpecialFeatureOptins;
    publisher: Publisher;
};
export const $globalPrivacyControlFlag = createStore<boolean | null>(null); // true means no consent for tracking
export const globalPrivacyControlFlagUpdated = createEvent<boolean>();
sample({
    clock: globalPrivacyControlFlagUpdated,
    target: $globalPrivacyControlFlag,
});

export const $targetingConsentStore = createStore<boolean | null>(null);
export const targetingConsentUpdated = createEvent<boolean | null>();
export const $gdprConsentStore = createStore<boolean | null>(null);
export const gdprConsentUpdated = createEvent<boolean | null>();
export const consentStoresInitFx = createEffect(() => {
    const lsTargetingConsent = LocalStorageService.getItem(LSKeys.targetingConsent, true);
    const lsGdprConsent = LocalStorageService.getItem(LSKeys.gdprCookieConsent, true);
    const INIT_TARGETING_CONSENT = lsTargetingConsent === 'true';
    const INIT_GDPR_CONSENT = lsGdprConsent === 'true';
    return {
        targetingConsent: INIT_TARGETING_CONSENT,
        gdprConsent: INIT_GDPR_CONSENT,
    };
});
sample({
    clock: consentStoresInitFx.doneData,
    fn: ({ targetingConsent }) => targetingConsent,
    target: targetingConsentUpdated,
});
sample({
    clock: consentStoresInitFx.doneData,
    fn: ({ gdprConsent }) => gdprConsent,
    target: gdprConsentUpdated,
});

const CHINA_REGIONS = new Set([CUSTOM_BEHAVIOUR_REGIONS.CN, CUSTOM_BEHAVIOUR_REGIONS.HK, CUSTOM_BEHAVIOUR_REGIONS.MO]);
export const NO_CMP_AND_ADS_REGIONS = new Set([...CHINA_REGIONS]);
const US_REGIONS = new Set([
    CUSTOM_BEHAVIOUR_REGIONS.US,
    CUSTOM_BEHAVIOUR_REGIONS.AS,
    CUSTOM_BEHAVIOUR_REGIONS.GU,
    CUSTOM_BEHAVIOUR_REGIONS.MP,
    CUSTOM_BEHAVIOUR_REGIONS.PR,
    CUSTOM_BEHAVIOUR_REGIONS.UM,
    CUSTOM_BEHAVIOUR_REGIONS.VI,
]);

const GDPR_REGIONS = new Set<string>([
    CUSTOM_BEHAVIOUR_REGIONS.AT,
    CUSTOM_BEHAVIOUR_REGIONS.AX,
    CUSTOM_BEHAVIOUR_REGIONS.BE,
    CUSTOM_BEHAVIOUR_REGIONS.BG,
    CUSTOM_BEHAVIOUR_REGIONS.CY,
    CUSTOM_BEHAVIOUR_REGIONS.CZ,
    CUSTOM_BEHAVIOUR_REGIONS.DE,
    CUSTOM_BEHAVIOUR_REGIONS.DK,
    CUSTOM_BEHAVIOUR_REGIONS.EE,
    CUSTOM_BEHAVIOUR_REGIONS.EL,
    CUSTOM_BEHAVIOUR_REGIONS.ES,
    CUSTOM_BEHAVIOUR_REGIONS.EU,
    CUSTOM_BEHAVIOUR_REGIONS.FI,
    CUSTOM_BEHAVIOUR_REGIONS.FR,
    CUSTOM_BEHAVIOUR_REGIONS.GB,
    CUSTOM_BEHAVIOUR_REGIONS.GF,
    CUSTOM_BEHAVIOUR_REGIONS.GI,
    CUSTOM_BEHAVIOUR_REGIONS.GP,
    CUSTOM_BEHAVIOUR_REGIONS.GR,
    CUSTOM_BEHAVIOUR_REGIONS.HR,
    CUSTOM_BEHAVIOUR_REGIONS.HU,
    CUSTOM_BEHAVIOUR_REGIONS.IE,
    CUSTOM_BEHAVIOUR_REGIONS.IS,
    CUSTOM_BEHAVIOUR_REGIONS.IT,
    CUSTOM_BEHAVIOUR_REGIONS.LI,
    CUSTOM_BEHAVIOUR_REGIONS.LT,
    CUSTOM_BEHAVIOUR_REGIONS.LU,
    CUSTOM_BEHAVIOUR_REGIONS.LV,
    CUSTOM_BEHAVIOUR_REGIONS.ME,
    CUSTOM_BEHAVIOUR_REGIONS.MF,
    CUSTOM_BEHAVIOUR_REGIONS.MQ,
    CUSTOM_BEHAVIOUR_REGIONS.MT,
    CUSTOM_BEHAVIOUR_REGIONS.NL,
    CUSTOM_BEHAVIOUR_REGIONS.NO,
    CUSTOM_BEHAVIOUR_REGIONS.PL,
    CUSTOM_BEHAVIOUR_REGIONS.PT,
    CUSTOM_BEHAVIOUR_REGIONS.RE,
    CUSTOM_BEHAVIOUR_REGIONS.RO,
    CUSTOM_BEHAVIOUR_REGIONS.SE,
    CUSTOM_BEHAVIOUR_REGIONS.SI,
    CUSTOM_BEHAVIOUR_REGIONS.SK,
    CUSTOM_BEHAVIOUR_REGIONS.YT,
    CUSTOM_BEHAVIOUR_REGIONS.UK,

    CUSTOM_BEHAVIOUR_REGIONS.CA,
    CUSTOM_BEHAVIOUR_REGIONS.BR,
]);

export const $isGDPRApplies = createStore<boolean | null>(null);
export const isGDPRAppliesUpdated = createEvent<boolean>();
export const $isCMPDisabled = createStore<boolean | null>(null);
export const $msInitialConsent = createStore<boolean | null>(null);
export const msInitialConsentUpdated = createEvent<{
    thirdPartyAdsOptOutCookie: string | null;
    isMicrosoft: boolean;
}>();

export const $isUS = createStore<boolean | null>(null).on(fetchUserCountryFx.doneData, (_, data) =>
    US_REGIONS.has(data.country_code as CUSTOM_BEHAVIOUR_REGIONS)
);

sample({
    clock: fetchUserCountryFx.doneData,
    fn: ({ country_code }) => GDPR_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS),
    target: isGDPRAppliesUpdated,
});
sample({
    clock: isGDPRAppliesUpdated,
    target: $isGDPRApplies,
});

sample({
    clock: msInitialConsentUpdated,
    source: $msInitialConsent,
    filter: (_, { isMicrosoft }) => isMicrosoft,
    fn: (_, { thirdPartyAdsOptOutCookie }) => {
        //check for 3PAdsOptOut on Microsoft domains
        if (thirdPartyAdsOptOutCookie === '1') {
            return false;
        }
        return true;
    },
    target: $msInitialConsent,
});

sample({
    clock: fetchUserCountryFx.doneData,
    source: $isMicrosoftDomain,
    fn: (isMicrosoft, { country_code }) => {
        const isUS = US_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS);
        return NO_CMP_AND_ADS_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS) || (isUS && isMicrosoft);
    },
    target: $isCMPDisabled,
});

//flow for MS
sample({
    clock: fetchUserCountryFx.doneData,
    source: {
        $isMicrosoftDomain,
        $msInitialConsent,
        $isGDPRApplies,
        $targetingConsentStore,
        $globalPrivacyControlFlag,
    },
    filter: ({ $isMicrosoftDomain }) => $isMicrosoftDomain,
    fn: (
        { $msInitialConsent, $isGDPRApplies, $targetingConsentStore, $globalPrivacyControlFlag },
        { country_code }
    ) => {
        const noCmp = NO_CMP_AND_ADS_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS);
        if (noCmp || $globalPrivacyControlFlag || $msInitialConsent === false) {
            return false;
        }

        if ($isGDPRApplies) {
            return $targetingConsentStore;
        }

        //true for the rest of the world
        return true;
    },
    target: [targetingConsentUpdated, gdprConsentUpdated],
});

sample({
    clock: $isCMPDisabled,
    source: { $targetingConsentStore, $isMicrosoftDomain, $isUS, $isGDPRApplies, $globalPrivacyControlFlag },
    filter: ({ $isMicrosoftDomain }) => !$isMicrosoftDomain,
    fn: ({ $targetingConsentStore, $isGDPRApplies, $isUS, $globalPrivacyControlFlag }, isCMPDisabled) => {
        if (isCMPDisabled || $globalPrivacyControlFlag) {
            return false;
        }
        const isAutoconsentRegion = !$isGDPRApplies && !$isUS;
        if (isAutoconsentRegion) {
            return true;
        }

        return $targetingConsentStore;
    },
    target: targetingConsentUpdated,
});

sample({
    clock: $isCMPDisabled,
    source: { $gdprConsentStore, $isMicrosoftDomain, $isUS, $isGDPRApplies, $globalPrivacyControlFlag },
    filter: ({ $isMicrosoftDomain }) => !$isMicrosoftDomain,
    fn: ({ $gdprConsentStore, $isGDPRApplies, $isUS, $globalPrivacyControlFlag }, isCMPDisabled) => {
        if (isCMPDisabled || $globalPrivacyControlFlag) {
            return false;
        }
        const isAutoconsentRegion = !$isGDPRApplies && !$isUS;
        if (isAutoconsentRegion) {
            return true;
        }

        return $gdprConsentStore;
    },
    target: gdprConsentUpdated,
});

sample({
    clock: $isCMPDisabled,
    source: $arenaDataStore,
    filter: ({ external_scripts }, isCMPDisabled) => Boolean(external_scripts?.length) && isCMPDisabled !== null,
    fn: ({ external_scripts }, isCMPDisabled) => {
        return isCMPDisabled
            ? (external_scripts as TExternalScriptRaw[]).filter((s) => !s.name.toLowerCase().includes('quantcast'))
            : external_scripts;
    },
    target: externalScriptsUpdated,
});

sample({
    clock: fetchUserCountryFx.doneData,
    fn: ({ country_code }) => {
        return NO_CMP_AND_ADS_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS);
    },
    target: $isCmpAndAdsDisabledByRegion,
});

export const gdprCmpUpdated = createEvent<CMPData>();
export const gdprCmpStartedFx = createEffect(() => {
    const gdprCmpUpdatedBound = scopeBind(gdprCmpUpdated, { safe: true });
    const pollInterval = setInterval(() => {
        if (window.__tcfapi) {
            clearInterval(pollInterval);
            window.__tcfapi('addEventListener', 2, gdprCmpUpdatedBound);
        }
    }, 100);
});
sample({
    clock: externalScriptLoaded,
    source: { $isUS, $globalPrivacyControlFlag, $isMicrosoftDomain, $msInitialConsent },
    filter: (
        {
            $isUS: isUS,
            $globalPrivacyControlFlag: isGPCEnabled,
            $isMicrosoftDomain: isMS,
            $msInitialConsent: msInitialConsent,
        },
        scriptName
    ) => {
        const isScriptLoaded = scriptName.toLowerCase().includes('quantcast');
        const commonCondition = isScriptLoaded && !isGPCEnabled && isUS === false;
        //check 3PAdsOptOut cookie additionaly for Microsoft globally, don't react to gdpr Popup until 3PAdsOptOut cookie is set to false
        return isMS ? Boolean(msInitialConsent) && commonCondition : commonCondition;
    },
    target: gdprCmpStartedFx,
});

sample({
    clock: gdprCmpUpdated,
    source: $targetingConsentStore,
    filter: (_, tcData) => tcData.eventStatus === 'useractioncomplete',
    fn: (targetingConsentStore, tcData) => {
        let targetingConsent = targetingConsentStore;
        try {
            if (tcData.gdprApplies) {
                targetingConsent = Boolean(tcData.purpose.consents[1]);
            } else {
                targetingConsent = true;
            }
        } catch (ex) {
            logger.debug(ex, 'Exception: targetingConsent = false');
            targetingConsent = false;
        }

        return targetingConsent;
    },
    target: targetingConsentUpdated,
});

sample({
    clock: gdprCmpUpdated,
    source: $gdprConsentStore,
    filter: (_, tcData) => tcData.eventStatus === 'useractioncomplete',
    fn: (gdprConsentStore, tcData) => {
        let consent = gdprConsentStore;
        try {
            if (tcData.gdprApplies) {
                consent = Boolean(tcData.purpose.consents[1]) && Boolean(tcData.vendor.consents[11]);
            } else {
                consent = true;
            }
        } catch (ex) {
            consent = false;
            logger.debug('Exception: gdprConsent  =' + LocalStorageService.getItem(LSKeys.gdprCookieConsent, true));
        }
        return consent;
    },
    target: gdprConsentUpdated,
});

sample({
    clock: targetingConsentUpdated,
    source: $isGDPRApplies,
    filter: (_, consent) => consent !== null,
    fn: (isGDPRApplies, targetingConsent) => {
        logger.debug(`gdprApplies = ${isGDPRApplies}, targetingConsent = ${targetingConsent}`);
        LocalStorageService.setItem(LSKeys.targetingConsent, targetingConsent, true);
        return targetingConsent;
    },
    target: $targetingConsentStore,
});

sample({
    clock: gdprConsentUpdated,
    source: $isGDPRApplies,
    filter: (_, consent) => consent !== null,
    fn: (isGDPRApplies, gdprConsent) => {
        logger.debug(`gdprApplies = ${isGDPRApplies}, gdprConsent = ${gdprConsent}`);
        LocalStorageService.setItem(LSKeys.gdprCookieConsent, gdprConsent, true);
        return gdprConsent;
    },
    target: $gdprConsentStore,
});

export const openGDPRCmpPopupFx = createEffect(() => {
    if (window.__tcfapi) {
        window.__tcfapi('displayConsentUi', 2, function () {});
    }
});
export const openUSPCmpPopupFx = createEffect(() => {
    if (window.__uspapi) {
        window.__uspapi('displayUspUi');
    }
});
